<template>
  <b-dropdown variant='link' no-caret ref='userMenuDropdown' right toggle-class='bg-white text-secondary shadow-none'>
    <template #button-content>
      <b-avatar :text='user_initial' variant='primary'>
      </b-avatar>
    </template>
    <div id="dropdown-menu"  class="d-flex flex-column px-5 py-3">
      <div class="d-flex flex-row">
        <b-avatar size="4rem" :text='user_initial' variant='primary'></b-avatar>
        <div id="user-info-section" class="ml-3">
          <h5 class="mt-2 mb-1">{{user_firstname}} {{user_lastname}}</h5>
          <p>{{user_email}}</p>
          <p>{{company_name}}</p>
        </div>
      </div>
      <b-dropdown-divider class="mt-2"></b-dropdown-divider>
      <b-dropdown-item :disabled='!canViewCompanyAdmin' class='overflow-hidden'
        link-class='text-decoration-none bg-white small p-0'>
        <router-link v-if='canViewCompanyAdmin' :to="{ name: 'CompanyAdministration' }"
          class='text-decoration-none text-secondary'>
          Company Administration
        </router-link>
      </b-dropdown-item>
      <b-dropdown-item @click='logout()' class='overflow-hidden'
        link-class='text-decoration-none bg-white text-secondary small p-0'>
        Logout
      </b-dropdown-item>
    </div>
  </b-dropdown>



</template>

<script>
import { CHANGE_USER, CHANGE_SHARE_GROUP }from '@/constants/permissions';
import { PERMISSIONS_RESET } from '@/store/actions/permissions';
import { mapGetters } from 'vuex';
import { AUTH_LOGOUT } from '@/store/actions/auth';
import { EventBus } from '@/network/eventbus';

export default {
  name: 'ApplicationMenuCard',
  created() {
    EventBus.$on('CLOSE_USER_DROPDOWN', this.closeDropdown);
  },
  computed: {
    canViewCompanyAdmin() {
      return (this.$store.getters['allowedPermissions'] || {})[CHANGE_USER] ||
        (this.$store.getters['allowedPermissions'] || {})[CHANGE_SHARE_GROUP];
    },
    user_initial() {
      let first_initial = '';
      let last_initial = '';

      if(this.user_firstname) {
        first_initial = this.user_firstname.charAt(0);
        if (this.user_lastname) {
          last_initial = this.user_lastname.charAt(0);
        }
        return first_initial + last_initial;
      } else {
        return this.user_email?.charAt(0);
      }
    },
    user_firstname() {
      return this.loggedInUser?.first_name;
    },
    user_lastname() {
      return this.loggedInUser?.last_name;
    },
    user_email() {
      return this.loggedInUser?.email;
    },
    company_name() {
      return this.userCompany?.name;
    },
    ...mapGetters(['loggedInUser', 'userCompany'])
  },
  methods: {
    async logout() {
      await this.$store.dispatch(AUTH_LOGOUT);
      this.$store.dispatch(PERMISSIONS_RESET);
      this.$router.push({ name: 'LoginPage' });
    },
    closeDropdown() {
      this.$refs.userMenuDropdown.hide(true);
    }
  }
};
</script>

<style scoped>
#user-info-section p {
 line-height: 1em;
 font-size: small;
}

#dropdown-menu {
  width: 14.063rem;
}

</style>